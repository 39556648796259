@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700");
@import url("https://fonts.googleapis.com/css?family=Inconsolata:400,700");
@import 'node_modules/bootstrap/scss/variables';

$bp : (
        mobile : 480px,
        big_mobile : 600px,
        tablet : 768px,
        desktop : 1440px,
);
@mixin query($display){
  @each $key, $value in $bp{

    //  defining max-width
    @if ($display == $key){
      @media (max-width: $value){@content;}
    }
  }
}
@mixin min($display){
  @each $key, $value in $bp{

    //  defining min-width
    @if ($display == $key){
      @media (min-width: $value){@content;}
    }
  }
}

// Buttons
$aex-button-text-color: #333;
$aex-button-background-color: white;
$aex-button-border-color: #ccc;
$aex-active-button-text-color: $aex-button-background-color;
$aex-active-button-background-color: $aex-button-text-color;

// Text
$aex-text-x-muted: lighten($text-muted, 20%);
$aex-text-xx-muted: lighten($text-muted, 50%);

// Feedback
$aex-error-feedback-color: #dc3545;

:root {
  /* Colors */
  --foreground-default: #08090a;
  --foreground-secondary: #41474d;

  --background-default: #fafbfe;
  --background-secondary: #022B43;

  --background-secondary-font: #FFFFFF;

  --nav-default: #ffffff;
  --nav-text: #1E1917;
  --primary-default: #8C1D5D;
  --secondary-default: #453F3D;

  --font-default: #1E1917;
  --header-color: #022B43;

  --footer-contact: #022B43;
  --primary-button: #022B43;
  --primary-text: #ffffff;

  --landing-1: #8C1D5D;
  --landing-2: #E94E1F;
  --landing-3: #0E5F88;
  --landing-heading-1: #8C1D5D;
  --landing-heading-2: #E94E1F;
  --landing-heading-3: #0E5F88;
  --landing-alt: #FFFFFF;
  --landing-card: #FFFFFF;
  --landing-bg: url("/assets/img/aex-landing-background.png");
  --landing-p: #453F3D;

  --secondary-alt: #453F3D;

  --transparent: #FFFFFF00;

  --horizontal-rule: #b2b0b1;

  --footer-text: #777777;

  --light-white: #FFFFFF;

  --dropdown-background: #F1F0F0;

  --order-button: #CF4F92;
  --pre-order-button: #E94E1F;

  --slider-background: #ccc;

  --error-red: red;

  --arrow-color: #a3a1a1;

  --border-color: rgba(206, 212, 218, 0.5);

  --shadow-color: #959595;

  --legend-color: #e7e9ec;

  --edit-address-link: #3F404A;

  --checkout-card-text: #022B43;

  /* Map Colors */
  --map-connected: #CF4F92;
  --map-construction: #E94E1F;
  --map-site-survey: #aa0000;
  --map-future: #0E5F88;

  --background-image: "";

  /* images */
  --logo: url("/assets/img/aexlogo.png");

  /* font */
  --font-regular: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
  --font-bold: 'proxima_nova_rgbold', Helvetica, Arial, sans-serif;
  --app-map-order-now:#F05636;
  --app-map-pre-order: #000000;
  --app-map-show-interest: #00AAFF;

  --app-header: #033C53;
  --app-footer: #033C53;
  --app-primary: #F05636;
  --app-secondary: #178FC4;
  --app-tertiary: #033C53;
  --app-other: #41474d;
  --app-white: #ffffff;
  --app-black: #000000;
  --app-success: #00dc25;
  --app-warning: #ff9100;
  --app-danger: #de3131;
  --app-info: #0150d5;
  --app-light: #6969b6;
  --app-first-logo: url("../img/first-zoomfibre-logo.png");
  --app-second-logo: url("../img/second-zoomfibre-logo.png");
  --app-whatsapp-logo: url("../img/whatsapp.png");
  --app-button-radius: 2rem;
  --app-button-large-width: 10rem;
  --app-button-medium-width: 8rem;
  --app-button-small-width: 6rem;
}

$edit-address-link: var(--edit-address-link);

  /* images */
$logo: var(--logo);
$background-image: var(--background-image);

  /* Colors */
$foreground-default: var(--foreground-default);
$foreground-secondary: var(--foreground-secondary);
$header-color: var(--header-color);

$nav-text: var(--nav-text);
$nav-default: var(--nav-default);

$background-default: var(--background-default);
$background-secondary: var(--background-secondary);
$background-secondary-font: var(--background-secondary-font);
$primary-default: var(--primary-default);
$secondary-default: var(--secondary-default);

$font-default: var(--font-default);

$font-regular: var(--font-regular);
$font-bold: var(--font-bold);

$contact-footer-colour: var(--footer-contact);
$contact-footer-text: var(--footer-text);
$primary-button-colour: var(--primary-button);
$primary-button-text: var(--primary-text);

$landing-1: var(--landing-1);
$landing-2: var(--landing-2);
$landing-3: var(--landing-3);
$landing-heading-1: var(--landing-heading-1);
$landing-heading-2: var(--landing-heading-2);
$landing-heading-3: var(--landing-heading-3);
$landing-alt: var(--landing-alt);
$landing-card: var(--landing-card);
$landing-bg: var(--landing-bg);
$landing-p: var(--landing-p);

$secondary-alt: var(--secondary-alt);

$transparent: var(--transparent);

$horizontal-rule: var(--horizontal-rule);

$footer-text: var(--footer-text);

$light-white: var(--light-white);

$dropdown-background: var(--dropdown-background);

$order-button: var(--order-button);
$pre-order-button: var(--pre-order-button);

$slider-background: var(--slider-background);

$error-red: var(--error-red);

$arrow-color: var(--arrow-color);

$border-color: var(--border-color);

$shadow-color: var(--shadow-color);

$legend-color: var(--legend-color);

$checkout-card-text: var(--checkout-card-text);

  /* Map Colors */
$map-connected: var(--map-connected);
$map-construction: var(--map-construction);
$map-site-survey: var(--map-site-survey);
$map-future: var(--map-future);

/* New color format */
$app-header: var(--app-header);
$app-footer: var(--app-footer);
$app-primary: var(--app-primary);
$app-secondary: var(--app-secondary);
$app-tertiary: var(--app-tertiary);
$app-other: var(--app-other);
$app-white: var(--app-white);
$app-black: var(--app-black);
$app-success: var(--app-success);
$app-warning: var(--app-warning);
$app-danger: var(--app-danger);
$app-info: var(--app-info);
$app-light: var(--app-light);
$app-first-logo: var(--app-first-logo);
$app-second-logo: var(--app-second-logo);
$app-whatsapp-logo: var(--app-whatsapp-logo);
$app-button-raduis: var(--app-button-radius);
$app-button-size-large: var(--app-button-large-width);
$app-button-size-small: var(--app-button-small-width);
$app-button-size-medium: var(--app-button-medium-width);
$app-map-order-now: var(--app-map-order-now);
$app-map-pre-order: var(--app-map-pre-order);
$app-map-show-interest: var(--app-map-show-interest);



