@import "imports";
@import '../../../../node_modules/primeng/resources/primeng.min.css';
@import  "~primeicons/primeicons.css";

@font-face {
	font-family: 'proxima_nova_rgregular';
	src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'proxima_nova_rgbold';
	src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* Global */
.content-container {
	background-color: $background-default;
}

.content-background {
	background-color: $background-default;
}

hr {
	border-top-color: $horizontal-rule, $secondary-default !important;
	color: $secondary-default !important;
	opacity: 0.4 !important;
}

h1,h2,h3,h4,h5 {
	color:$header-color;
}

h4 {
	font-family: $font-bold !important;

	font-weight: 800 !important;
	font-size: 2.5em !important;
	line-height: 1em !important;

	letter-spacing: 1.2px;
}

.heading {
	font-family: 'proxima_nova_rgbold', sans-serif !important;
	font-style: normal;
	font-weight: 800;
	font-size: 28px ;
	line-height: 30px;

	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 1.2px;
	text-transform: uppercase;
	margin-bottom: 2rem !important;
}

.title-case {
	text-transform: capitalize !important;
}

.button-secondary.active {
	color: $primary-button-text;
	background-color: $primary-button-colour;
}

button {
	border-radius: 4px;
	text-align: center;
	height: 40px;
	line-height: 1.7 !important;
}

.btn {
	display: block !important;
	width: 163px;
	margin: auto;
	border: 0;
}

.btn-sm {
	padding: 0.25rem 0.5rem !important;
	line-height: 1.7 !important;
	font-size: 0.9em !important;
	height: 55px !important;
}

h5 {
	opacity: 1;
}

.accept-terms * {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 21px;
	color: $secondary-alt;
}

.accept-terms span {
	opacity: 0.5;
}


.button-primary, .btn-default {
	font-family: $font-regular !important;
	font-size: 1em !important;
	color: $primary-button-text;
	background-color: $primary-button-colour;
	border-radius: 4px;
	border:0;
	padding: 8px 18px;
	opacity: 1;
	text-transform: uppercase;
}

.button-primary:disabled {
	opacity: 0.6  !important;
}

.button-primary.invalid, .button-primary.inactive {
	opacity: 0.65;
}

.button-primary:hover, .button-primary.valid, .button-primary.active {
	opacity: 1;
}

.button-secondary {
	font-family: $font-regular !important;
	font-size: 1em !important;
	color:$background-secondary;
	background-color: $transparent;
	border-radius: 4px;
	border:0;
	padding: 8px 18px;
}

.button-secondary:disabled {
	opacity: 0.6;
}

@media (max-width: 768px) {
	button {
		padding: 8px 12px;
	}
	.button-primary, .button-secondary {
		font-size: 0.9em !important;
	}
}

label {
	color:$primary-default;
}

::placeholder {
	opacity: 0.6;
}

.primary-color {
	color:$primary-default !important;
}

.secondary-color {
	color:$secondary-default !important;
}

.header-color {
	color:$header-color !important;
}

app-footer .terms {
	font-style: normal;
	font-weight: normal;
	font-size: 0.72em;
	line-height: 21px;
	text-align: center;
	padding: 0;
	margin: 0;
	color: $footer-text;
}

.type12 {
	font-size: 0.75em !important;
}

.type14 {
	font-size: 0.87em !important;
}

.type16 {
	font-size: 1em !important;
}

.type20 {
	font-size: 1.25em !important;
}

.type24 {
	font-size: 1.5em !important;
}

@media (max-width: 500px) {
	app-footer .terms {
		font-size: 0.5em !important;
	}

	app-footer p {
		font-size: 0.8em !important;
	}

	.type20 {
		font-size: 1em !important;
	}

	.type24 {
		font-size: 1.2em !important;
	}

	h4 {
		font-size: 1.5em !important;
	}
}

.footer-rounded {
	border-radius: 16px;
}

.spacer10 {
	height:10px !important
}

.spacer20 {
	height:20px !important
}

.spacer50 {
	height:50px !important
}

.op40 {
	opacity: 0.4 !important;
}

.op60 {
	opacity: 0.6 !important;
}

.gap {
	gap:1.5em !important;
}

.pointer {
	cursor: pointer;
}

.card-header {
	border-top:0;
	border-bottom: 1px solid $horizontal-rule;
	background-color: $transparent !important;
}

.capitalize {
	text-transform: uppercase;
}

.arrow-down {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;

	border-top: 10px solid $arrow-color;
}

.arrow-up {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;

	border-bottom: 10px solid $arrow-color;
}

.arrow-product {
	margin-top: 5px;
	float: right;
}

.container-grid {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
}

@media (min-width: 1200px) {
	.container-grid {
		max-width: 1140px;
	}
}
@include query(big_mobile) {
	.container-login {
		margin-top: 15vh;
	}
}
.container-login {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 5vh;
	max-width: 440px;
}
.container-password {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 5vh;
	max-width: 550px;
}
/* Top Level Adjust */
app-header {
	background-color: $nav-default;
	color: $nav-text;
}

.map-canvas-input {
    border-right: 20px solid $legend-color;
    border-left: 20px solid $legend-color;
}

.multiselect-item-checkbox div::before {
    border: 2px solid $primary-default !important;
	background-color: $transparent !important;
}

.multiselect-item-checkbox div::after {
    border-color: $primary-default !important;
}

.multiselect-dropdown .dropdown-btn {
	overflow:hidden !important;
}


/* Toggle Switch*/
.switch {
	position: relative;
	display: inline-block;
	width: 48px;
	height: 22px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $slider-background;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 14px;
	width: 14px;
	left: 4px;
	bottom: 4px;
	background-color: $light-white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .slider {
	background-color: $primary-default;
}

input:focus + .slider {
	box-shadow: 0 0 1px $primary-default;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

.slider.round {
	border-radius: 22px;
}

.slider.round:before {
	border-radius: 50%;
}

/* Date Picker */
.bs-datepicker-head {
    background-color: $primary-default !important;
}

.bs-datepicker-body table td.week span {
    color: $primary-default !important;
}

.bs-datepicker-body span.selected {
    background-color: $primary-default !important;
}
.card {
	box-shadow: 10px 10px 43px -18px rgba(0, 0, 0, 0.50);
}

/* Repair Accordion */
.collapse, .card-header {
    padding-left:0 !important;
    padding-right:0 !important;
}

/* Repair Bottom Scroll Bar */
app-login > div {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

/* Set All Fonts */
strong {
	font-family: $font-bold !important;
}

a,div,label,p,span {
	font-family: $font-regular !important;
}


.button-primary.outline {
	border: 1px solid var(--primary-button);
	background-color: var(--background-default);
	color: var(--primary-button);
}
.button-primary.clear {
	border: 1px solid var(--background-default);
	background-color: var(--background-default);
	color: var(--primary-button);
}

// *** FIXES
.modal-backdrop {
	z-index: 1050 !important;
}

// *** MATERIAL BUTTONS
.mat-button,
.mat-flat-button,
.mat-stroked-button,
.mat-raised-flat-button {
	width: 163px;
}

.mat-button.mat-primary,
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-stroked-button.mat-primary,
.mat-fab-button.mat-primary,
.mat-min-fab-button.mat-primary {
	background-color: var(--background-secondary) !important;
}

// *** MATERIAL AUTOCOMPLETE
.premise-list {
	.mat-form-field {
		width: 100%
	}

	.mat-form-field-appearance-outline .mat-form-field-infix {
		padding: 0.5em 0 !important;
	}

	.mat-form-field-infix {
		border-top: 8px solid transparent !important;
	}

	span.mat-button-wrapper {
		font-size: 0.75rem !important;
	}

	.mat-form-field-appearance-outline .mat-form-field-wrapper {
		margin: 0 !important;
	}

	.mat-form-field-appearance-outline {
		.mat-form-field-outline {
			background-color: #ffffff !important;
			border-radius: 4px !important;
		}
	}
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
	color: rgba(0,0,0,.12) !important;
}

// *** MATERIAL RADIO
.mat-radio-label {
	padding: 0px 8px;
}

.mat-radio-label-content {
	padding-left: 16px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	background-color: $dropdown-background !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
	background-color: $primary-default !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: $primary-default !important;
}

// *** PRIMENG MULTISELECT
.p-multiselect {
	width: 100%;
	background: $background-default !important;
	height: 50px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
	background: transparent !important;
	border: 1px solid $primary-default !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
	box-shadow: inset 0 0 0 0.15rem $primary-default !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
	background: transparent !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
	border-color: $primary-default !important;
	background: transparent !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
	border-color: $primary-default !important;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
	color: $primary-default !important;
}

.p-inputtext:enabled:hover {
	border-color: $primary-default !important;
}

.p-inputtext:enabled:focus {
	box-shadow: 0 0 0 0.1rem $secondary-default !important;
	border-color: $primary-default !important;
}

.p-multiselect,
.p-multiselect:not(.p-disabled):hover {
	border-color: $dropdown-background !important;
}

.p-multiselect .p-multiselect-label.p-placeholder {
	height: 50px;
	color: #000 !important;
	background: $dropdown-background;
	display: flex;
	align-items: center;
}

.p-multiselect .p-multiselect-trigger {
	background: $dropdown-background !important;
	padding-right: 8px;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
	padding: 0.5rem 0.5rem !important;
	background: $dropdown-background;
}

.pi {
	font-family: 'primeicons' !important;
}

p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
	background: #f1f0f0;
}



.slider-header,
.slider-footer {
	width: 100%;
	font-size: 1rem;
	font-weight: 400;
	color: #55637d;

	p {
		margin-bottom: 0px;
	}
}
.slider-header {
	padding: 0px 8px 0.75rem 8px;
}
.slider-footer {
	font-size: 0.75rem;
	top: -16px;
	position: relative;
	padding: 0px 8px;
}

.mat-slider {
	padding: 0px 8px !important;
	width: 100%;
}

.mat-slider-track-fill {
	background-color: $primary-button-colour !important;
}
.mat-slider-track-background {
	background-color: $slider-background !important;
}

.mat-slider-thumb,
.mat-slider-thumb-label {
	background-color: $primary-button-colour !important;

}
.mat-slider-thumb-label-text {
	color: $secondary-default !important;
}

/*New look and  feel buttons*/

.btn-app {
	text-transform: uppercase;
	color: $app-white !important;
	border-radius: $app-button-raduis !important;
	font-weight: 600;
	font-size: 13px;
	padding: 10px;
	letter-spacing: 1.5px;
	width: $app-button-size-large;
	&-map-order-now {
		background-color: $app-map-order-now !important;
		border: 1px solid $app-map-order-now;
	}
	&-map-pre-order {
		background-color: $app-map-pre-order !important;
		border: 1px solid $app-map-pre-order;
	}
	&-map-show-interest {
		background-color: $app-map-show-interest !important;
		border: 1px solid $app-map-show-interest;
	}
	&-tertiary {
		background-color: $app-tertiary !important;
		border: 1px solid $app-tertiary;
	}
	&-secondary {
		background-color: $app-secondary !important;
		border: 1px solid $app-secondary;
	}
	&-primary {
		background-color: $app-primary !important;
		border: 1px solid $app-primary;
	}
	&-danger {
		background-color: $app-danger !important;
		border: 1px solid $app-danger;
	}
	&-success {
		background-color: $app-success !important;
		border: 1px solid $app-success;
	}
	&-warning {
		background-color: $app-warning !important;
		border: 1px solid $app-warning;
	}
	&-info {
		background-color: $app-info !important;
		border: 1px solid $app-info;
	}
	&-light {
		background-color: $app-light !important;
		border: 1px solid $app-light;
	}
}

.bg-app {
	color:$app-white !important;
	&-tertiary {
		background-color: $app-tertiary !important;
	}
	&-secondary {
		background-color: $app-secondary !important;
	}
	&-primary {
		background-color: $app-primary !important;
	}
	&-danger {
		background-color: $app-danger !important;
	}
	&-success {
		background-color: $app-success !important;
	}
	&-warning {
		background-color: $app-warning !important;
	}
	&-info {
		background-color: $app-info !important;
	}
	&-light {
		background-color: $app-light !important;
	}

}
.zoom-dialog-container .mat-dialog-container {
	background: #033C53 ;
	color:#fff !important;
}

.zoom-dialog-container .mat-dialog-container h3{
	color:#fff !important;
}
.zoom-dialog-container .mat-dialog-container {
	background: #033C53 ;
	color:#fff !important;
}

.zoom-dialog-container .mat-dialog-container h3{
	color:#fff !important;
}

.gm-style-iw-chr {
	display: none;
}
